<template>
  <div class="elite-tabs-wrapper-content">
    <section class="user-security">
      <h3>{{ $t("PROFILE.YOUR_SECURITY") }}</h3>
      <ul class="list-group">
        <li
          v-if="!user.is_2fa_enabled"
          class="list-group-item d-flex justify-content-between align-content-center"
        >
          <a href="#" @click="toggle2FA">
            {{ $t("PROFILE.ENABLE_2FA") }}
            <base-switch :value="user.is_2fa_enabled" />
          </a>
          <span class="d-flex align-content-center">
            <span>
              {{ $t("PROFILE.DISABLE_2FA_AT") }}
              {{ user.two_fa_enabled_at | moment("DD MMM YYYY") }}
            </span>
          </span>
        </li>
        <li
          v-if="user.is_2fa_enabled"
          class="list-group-item d-flex justify-content-between align-content-center"
        >
          <a href="#" @click="toggle2FA">
            {{ $t("PROFILE.DISABLE_2FA") }}
            <base-switch :value="user.is_2fa_enabled" />
          </a>
          <span class="d-flex align-content-center">
            <span>
              {{ $t("PROFILE.ENABLE_2FA_AT") }}
              {{ user.two_fa_disabled_at | moment("DD MMM YYYY") }}
            </span>
          </span>
        </li>
      </ul>
    </section>
  </div>
</template>
<script>
import { Option, Select } from "element-ui";
import swal from "sweetalert2";
import formMixin from "@/mixins/form-mixin";
import BaseSwitch from "@/components/Inputs/BaseSwitch.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseSwitch,
  },

  mixins: [formMixin],

  props: ["user"],

  data() {
    return {};
  },

  mounted() {},

  methods: {
    async toggle2FA() {
      const confirmation = await swal.fire({
        title: this.$t(
          `COMMON.${this.user.is_2fa_enabled ? "DISABLE_2FA" : "ENABLE_2FA"}`
        ),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation) {
        return;
      }

      try {
        await this.$store.dispatch("profile/toggle2fa", {});
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t(
            `PROFILE.${
              this.user.is_2fa_enabled
                ? "DISABLE_2FA_SUCCESS"
                : "ENABLE_2FA_SUCCESS"
            }`
          ),
        });
        this.$store.dispatch("logout");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
      }
    },
  },

  created() {},
};
</script>
